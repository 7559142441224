@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

html,
body,
app-root {
  height: 100%;
  display: block;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  background: var(--h-main-background-color);
}
body {
  -webkit-font-smoothing: subpixel-antialiased;
  letter-spacing: 0.5px;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}
button {
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

body,
p {
  margin: 0;
  padding: 0;
}
body.provider-healent {
  --accent: #e9e9e9;
  --primary-color: #000c2a;
  --secondary-color: #adaaa8;
  --red: #e2211c;
  --green: #1b998b;
  --gray-1: #adaaa8;
  --gray-2: #cfcbc4;
  --white: white;
  --h-main-background-color: #faf7f2;
  --topbar-color: #feffff;
}
body.provider-heal {
  --accent: #e9e9e9;
  --primary-color: #000c2a;
  --secondary-color: #adaaa8;
  --red: #e2211c;
  --green: #1b998b;
  --gray-1: #adaaa8;
  --gray-2: #cfcbc4;
  --white: white;
  --h-main-background-color: #faf7f2;
  --topbar-color: #feffff;
}

body.provider-akps {
  --accent: #38a3c7;
  --primary-color: #000c2a;
  --secondary-color: #adaaa8;
  --red: #e2211c;
  --green: #1baf2f;
  --gray-1: #adaaa8;
  --gray-2: #cfcbc4;
  --white: white;
  --h-main-background-color: #f2ebe1;
  --topbar-color: #faf7f2;
}
body.provider-cps {
  --accent: #d1a652;
  --primary-color: #041d26;
  --secondary-color: #adc0cc;
  --red: #e21c1c;
  --green: #00a874;
  --gray-1: #adc0cc;
  --gray-2: #e2eaf0;
  --white: white;
  --h-main-background-color: #f5f9f9;
  --topbar-color: #244c5a;
}

app-blue-client {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
}
body {
  --size-xs: 4px;
  --size-s: 8px;
  --size-sm: 12px;
  --size-m: 16px;
  --size-sl: 24px;
  --size-l: 32px;
  --size-xl: 40px;
  --size-48: 48px;
  --size-xml: 56px;
  --size-xxl: 64px;
  --font-size-xs: 14px;
  --font-size-s: 16px;
  --font-size-m: 20px;
  --font-size-l: 24px;
  --line-height-s: 16px;
  --line-height-m: 20px;
  --line-height-l: 26px;

  /* toolbar */
  --mat-toolbar-container-background-color: var(--topbar-color);
  --mat-toolbar-container-text-color: var(--primary-color);
  /* stepper */
  --mat-stepper-container-color: var(--h-main-background-color);
  --mat-stepper-header-icon-foreground-color: var(--white);
  --mat-stepper-header-selected-state-icon-background-color: var(--green);
  --mat-stepper-header-done-state-icon-background-color: var(--accent);
  --mat-stepper-header-edit-state-icon-background-color: var(--green);
  --mat-stepper-line-color: var(--gray-1);
  --mat-stepper-header-hover-state-layer-color: transparent;
  --mat-stepper-header-focus-state-layer-color: transparent;
  --mat-stepper-header-label-text-color: var(--primary-color);
  --mat-stepper-header-optional-label-text-color: var(--secondary-color);
  --mat-stepper-header-selected-state-label-text-color: var(--primary-color);
  --mat-stepper-header-error-state-label-text-color: var(--red);
  --mat-stepper-header-icon-background-color: var(--red);
  --mat-stepper-header-error-state-icon-foreground-color: var(--red);
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-stepper-header-label-text-size: var(--font-size-m);
  --mat-stepper-header-label-text-weight: 500;
  --mat-stepper-header-error-state-label-text-size: var(--font-size-m);
  --mat-stepper-header-selected-state-label-text-size: var(--font-size-m);
  --mat-stepper-header-selected-state-label-text-weight: 500;
  --mat-icon-color: var(--primary-color);
  /* Radio */
  --mdc-radio-state-layer-size: 32px;
  --mdc-text-button-label-text-tracking: 0.5px;
  --mat-form-field-subscript-text-tracking: 0.5px;
  /* Button */
  --mdc-text-button-container-shape: 5px;
  --mdc-filled-button-container-shape: 5px;
  --mdc-protected-button-container-shape: 5px;
  --mdc-outlined-button-container-shape: 5px;
  --mdc-protected-button-container-color: var(--primary-color);
  --mdc-protected-button-label-text-color: var(--white);
  --mdc-protected-button-disabled-container-color: var(--gray-1);
  --mdc-protected-button-disabled-label-text-color: var(--white);
  --mdc-protected-button-container-shadow-color: var(--white);
  --mat-protected-button-state-layer-color: var(--white);
  --mat-protected-button-disabled-state-layer-color: var(--white);
  --mdc-protected-button-label-text-size: var(--font-size-s);
  --mat-protected-button-hover-state-layer-opacity: 0;
  --mat-protected-button-focus-state-layer-opacity: 0;
  --mat-protected-button-pressed-state-layer-opacity: 0;
  --mdc-outlined-button-label-text-color: var(--primary-color);
  --mat-standard-button-toggle-selected-state-text-color: var(--white);
  --mat-standard-button-toggle-selected-state-background-color: var(
    --primary-color
  );
  --mat-stepper-header-edit-state-icon-foreground-color: var(--white);
  --mat-standard-button-toggle-text-color: var(--primary-color);
  --mat-standard-button-toggle-background-color: var(--white);
  --mat-standard-button-toggle-divider-color: var(--gray-1);
  --mdc-text-button-label-text-color: var(--white);
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0);
  --mat-text-button-state-layer-color: var(--white);
  --mat-text-button-disabled-state-layer-color: var(--white);
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0);
  --mat-text-button-hover-state-layer-opacity: 0;
  --mat-text-button-focus-state-layer-opacity: 0;
  --mat-text-button-pressed-state-layer-opacity: 0;
  --mdc-filled-button-container-color: var(--primary-color);
  --mdc-filled-button-label-text-color: var(--white);
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0);
  --mat-filled-button-state-layer-color: var(--white);
  --mat-filled-button-disabled-state-layer-color: var(--white);
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);

  /* Dialog */
  --mat-bottom-sheet-container-text-color: var(--primary-color);
  --mat-bottom-sheet-container-background-color: var(--h-main-background-color);
  --mdc-dialog-subhead-color: var(--primary-color);

  /* Inputs */
  --mdc-filled-text-field-label-text-weight: 500;
  --mdc-filled-text-field-caret-color: var(--accent);
  --mdc-filled-text-field-focus-active-indicator-color: var(--accent);
  --mdc-filled-text-field-focus-label-text-color: var(--accent);
  --mdc-filled-text-field-container-color: var(--white);
  --mdc-filled-text-field-disabled-container-color: var(--gray-2);
  --mdc-filled-text-field-label-text-color: var(--primary-color);
  --mdc-filled-text-field-hover-label-text-color: var(--primary-color);
  --mdc-filled-text-field-input-text-color: var(--primary-color);
  --mdc-filled-text-field-error-hover-label-text-color: var(--red);
  --mdc-filled-text-field-error-focus-label-text-color: var(--red);
  --mdc-filled-text-field-error-label-text-color: var(--red);
  --mdc-filled-text-field-error-caret-color: var(--red);
  --mdc-filled-text-field-hover-active-indicator-color: var(--primary-color);
  --mdc-filled-text-field-error-active-indicator-color: var(--red);
  --mdc-filled-text-field-error-focus-active-indicator-color: var(--red);
  --mdc-filled-text-field-error-hover-active-indicator-color: var(--red);
  --mdc-outlined-text-field-caret-color: var(--accent);
  --mdc-outlined-text-field-focus-outline-color: var(--accent);
  --mdc-outlined-text-field-input-text-color: var(--primary-color);
  --mdc-outlined-text-field-error-caret-color: var(--red);
  --mdc-outlined-text-field-error-focus-label-text-color: var(--red);
  --mdc-outlined-text-field-error-label-text-color: var(--red);
  --mdc-outlined-text-field-error-hover-label-text-color: var(--red);
  --mdc-outlined-text-field-hover-outline-color: var(--primary-color);
  --mdc-outlined-text-field-error-focus-outline-color: var(--red);
  --mdc-outlined-text-field-error-hover-outline-color: var(--red);
  --mdc-outlined-text-field-error-outline-color: var(--red);
  --mat-form-field-state-layer-color: var(--primary-color);
  --mat-form-field-error-text-color: var(--red);
  --mat-form-field-hover-state-layer-opacity: 0;
  --mat-form-field-focus-state-layer-opacity: 0;
  --mat-checkbox-label-text-color: var(--primary-color);
  --mat-form-field-subscript-text-size: 14px;

  /* Checkbox */
  --mdc-checkbox-state-layer-size: 32px;
  --mdc-checkbox-unselected-icon-color: var(--gray-1);
  --mdc-checkbox-disabled-selected-icon-color: var(--gray-1);
  --mdc-checkbox-disabled-unselected-icon-color: var(--gray-1);
  --mdc-checkbox-selected-checkmark-color: var(--white);
  --mdc-checkbox-selected-focus-icon-color: var(--accent);
  --mdc-checkbox-selected-hover-icon-color: var(--accent);
  --mdc-checkbox-selected-icon-color: var(--accent);
  --mdc-checkbox-selected-pressed-icon-color: var(--accent);
  --mdc-checkbox-unselected-focus-icon-color: var(--gray-1);
  --mdc-checkbox-unselected-hover-icon-color: var(--gray-1);
  --mdc-checkbox-unselected-icon-color: var(--gray-1);
  --mdc-checkbox-unselected-pressed-icon-color: var(--gray-1);
  --mdc-checkbox-selected-focus-state-layer-color: var(--accent);
  --mdc-checkbox-selected-hover-state-layer-color: var(--accent);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--accent);
  --mdc-checkbox-unselected-focus-state-layer-color: var(--gray-1);
  --mdc-checkbox-unselected-hover-state-layer-color: var(--gray-1);
  --mdc-checkbox-unselected-pressed-state-layer-color: var(--gray-1);

  /* Card */
  --mdc-elevated-card-container-shape: 5px;
  --mdc-outlined-card-container-shape: 5px;
  --mdc-outlined-card-outline-width: 0px;

  /* date piker */
  --mat-datepicker-calendar-date-selected-state-background-color: var(--accent);
  --mat-datepicker-toggle-active-state-icon-color: var(--accent);
  --mat-datepicker-calendar-date-text-color: var(--primary-color);
  --mat-form-field-container-text-weight: 700;
}
.h-main .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: var(--accent);
  --mdc-linear-progress-track-color: var(--gray-2);
}
.h-main .mat-step-icon {
  width: var(--size-l);
  height: var(--size-l);
}
.h-main .mat-vertical-stepper-header {
  padding-left: var(--size-m);
}

.mat-mdc-checkbox {
  --mat-checkbox-label-text-line-height: var(--font-size-m);
  --mat-checkbox-label-text-size: var(--font-size-s);
  --mat-checkbox-label-text-weight: 500;
}
.color-red {
  color: var(--red);
}
.color-primary {
  color: var(--primary-color);
}
.color-accent {
  color: var(--accent);
}

.fw-b {
  font-weight: 700;
}
.fw-600 {
  font-weight: 700;
}
.fw-500 {
  font-weight: 500;
}

.m-l-16 {
  margin-left: 16px;
}
.h-main .mat-mdc-raised-button {
  letter-spacing: 0.5px !important;
}
.error-checkbox .sub-title {
  color: var(--mat-form-field-error-text-color) !important;
}

.error-checkbox .mdc-form-field > label {
  color: var(--mat-form-field-error-text-color) !important;
}

.error-checkbox .mdc-radio__background .mdc-radio__outer-circle {
  border-color: var(--mat-form-field-error-text-color) !important;
  border-width: 2px;
}
.h-main .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: var(--accent);
  --mdc-linear-progress-track-color: var(--white);
}

.w-100 {
  width: 100%;
}

.w-90 {
  width: 90% !important;
}

.w-700 {
  max-width: 700px;
}

.w-50 {
  width: 50%;
}

.w-48 {
  width: 48%;
}

.mat-step {
  max-width: 720px;
  margin: auto;
}
.w-30 {
  width: 33%;
}
.red-front {
  color: var(--red) !important;
}
.primary-front {
  color: var(--primary-color);
}
.stepper {
  max-width: 100%;
  background: transparent;
  margin: auto;
}

.h-main {
  background: var(--h-main-background-color);
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}
.h-main .mat-mdc-progress-bar {
  position: absolute;
  top: 66px;
}
.primary-front {
  color: var(--primary-color);
}
.text-center {
  text-align: center;
}
.hint-text {
  font-size: 14px;
  font-weight: 400;
  color: var(--primary-color);
  line-height: 16px;
  margin-bottom: 16px;
  margin-top: -8px;
  padding: 0 16px;
}
.hint-text.no-m {
  margin-top: 8px;
}
.d-block {
  display: block;
}
.p-t-l-m {
  padding-top: 8px;
  padding-left: 16px;
}
.w-70 {
  width: 63%;
}

.d-flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.privacy label {
  text-decoration: underline !important;
  cursor: pointer !important;
}
.privacy .error label {
  color: var(--red) !important;
}
.privacy .error .mdc-checkbox__background {
  border-color: var(--red) !important;
}

.m-t-m {
  margin-top: var(--size-m);
}
.m-t-l {
  margin-top: var(--size-l);
}
.m-t-48 {
  margin-top: var(--size-48);
}

.m-b-xxs {
  margin-bottom: var(--size-xs);
}

.m-b-m {
  margin-bottom: var(--size-m);
}

.m-b-l {
  margin-bottom: var(--size-l);
}

.m-b-xl {
  margin-bottom: var(--size-xl);
}
.p-t-l {
  padding-top: var(--size-l);
}

.p-t-m {
  padding-top: var(--size-m);
}

.p-b-m {
  padding-bottom: var(--size-m);
}
.p-b-xs {
  padding-bottom: var(--size-xs);
}
.p-b-0 {
  padding-bottom: 0 !important;
}
.h-main .p-b-0 .mat-mdc-form-field {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
.p-l-s {
  padding-left: var(--size-s);
}
.p-t-s {
  padding-top: var(--size-s);
}
.p-r-s {
  padding-right: var(--size-s);
}
.p-r-m {
  padding-right: 12px;
}

.p-r-xs {
  padding-right: var(--size-xs);
}
.h-main .mat-step-label {
  line-height: 1.5;
}
.font-500 {
  font-weight: 500;
}
.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
  margin: 0;
}

.intro-title {
  color: var(--primary-color);
  font-size: var(--font-size-s);
  font-weight: 500;
  line-height: var(--line-height-m);
}

.intro-body {
  color: var(--accent);
  font-size: var(--font-size-s);
  font-weight: 400;
  line-height: var(--line-height-m);
}

.h-main .mat-mdc-radio-button .mdc-radio {
  padding: 0;
  background: #ffffff;
  margin: 0 var(--size-s) 0 0;
  border-radius: 100%;
  overflow: hidden;
  width: var(--size-l);
  height: var(--size-l);
}
.h-main .mat-mdc-radio-button .mdc-radio__background {
  width: var(--size-l);
  height: var(--size-l);
}

.mat-mdc-radio-button
  .mdc-radio__native-control:focus
  + .mdc-radio__background::before {
  opacity: 0 !important;
}

.h-main .radio-title {
  display: block;
  font-weight: 500;
  margin-bottom: var(--size-m);
  margin-top: var(--size-m);
}
.h-main .get-verefi-btn:disabled {
  background: var(--gray-2);
  color: var(--white);
  border: 0;
}
.h-main
  .mat-mdc-radio-button.mat-mdc-radio-checked
  .mdc-radio__background::before {
  background-color: var(--red);
}

.h-main .mat-mdc-radio-group {
  display: flex;
  justify-content: space-between;
  height: var(--size-l);
}
.h-main .popup-container span.title {
  font-size: var(--font-size-m);
  font-weight: 700;
  line-height: 1;
  padding: 0;
  margin: 0;
  margin-bottom: var(--size-l);
  display: block;
}
.h-main .popup-container .mat-mdc-radio-group {
  display: flex;
  justify-content: space-between;
  height: auto;
  flex-direction: column;
  gap: var(--size-sl);
}
.h-main
  .popup-container
  .mat-mdc-radio-group
  .mdc-form-field.mat-internal-form-field {
  cursor: pointer;
  width: 100%;
}
.h-main .mdc-form-field > label {
  padding-left: 0;
  font-size: var(--font-size-s);
  font-weight: 500;
  color: var(--primary-color);
  cursor: pointer;
  width: 100%;
}

.h-main .error .mdc-form-field > label {
  color: var(--red);
}

.h-main
  .mat-mdc-radio-button
  .mdc-radio:not(:disabled):active
  .mdc-radio__native-control:enabled:checked
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border-color: var(--primary-color);
  border-width: 2px;
}

.h-main .mdc-radio__background {
  width: var(--size-l);
  height: var(--size-l);
}

.h-main .mdc-radio {
  width: var(--size-l);
  height: var(--size-l);
}

.h-main .mat-mdc-radio-button .mat-radio-ripple {
  display: none;
}

.h-main .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: var(--primary-color);
  --mdc-radio-disabled-unselected-icon-color: var(--primary-color);
  --mdc-radio-unselected-hover-icon-color: var(--primary-color);
  --mdc-radio-unselected-icon-color: var(--primary-color);
  --mdc-radio-unselected-pressed-icon-color: var(--primary-color);
  --mdc-radio-selected-focus-icon-color: var(--accent);
  --mdc-radio-selected-hover-icon-color: var(--accent);
  --mdc-radio-selected-icon-color: var(--accent);
  --mdc-radio-selected-pressed-icon-color: var(--accent);
  --mat-radio-ripple-color: var(--primary-color);
  --mat-radio-checked-ripple-color: var(--accent);
  --mat-radio-disabled-label-color: var(--primary-color);
}

.h-main .mdc-radio__inner-circle {
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  transform: translate(-50%, -50%) scale(0, 0);
  border-width: 0;
  background: var(--accent);
}
.h-main .custom-spinner .mat-progress-spinner-circle {
  stroke: var(--accent);
}

.h-main
  .mdc-radio__native-control:checked
  + .mdc-radio__background
  .mdc-radio__inner-circle {
  transform: translate(-50%, -50%) scale(1);
}

.h-main
  .mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled:checked
  + .mdc-radio__background
  .mdc-radio__outer-circle,
.h-main
  .mat-mdc-radio-button
  .mdc-radio:hover
  .mdc-radio__native-control:enabled:checked
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border-color: var(--primary-color);
}
.h-main .mat-mdc-raised-button:not(:disabled) {
  font-weight: 700;
}
.h-main .next-btn,
.h-main .start-btn {
  display: flex;
  gap: var(--size-s);
  font-weight: 700;
  margin-top: var(--size-l);
}
.btn-right {
  justify-content: flex-end;
}
.h-main .next-btn .mat-mdc-button.back-btn {
  background: var(--white);
  color: var(--primary-color);
}
.h-main .add-btn {
  width: 100%;
  height: 56px;
  font-size: var(--font-size-s);
  font-weight: 700;
  background: var(--white);
  color: var(--primary-color);
  border: 0;
  margin-left: 0;
  margin-bottom: var(--size-l);
  cursor: pointer;
}
h3.upload-title {
  font-size: var(--font-size-s);
  font-weight: 500;
  color: var(--primary-color);
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 8px;
}
h3.upload-title span {
  padding-left: 4px;
}
.h-main .add-btn > .mat-icon {
  font-size: var(--font-size-l);
  width: var(--font-size-l);
  height: var(--font-size-l);
}
.upload-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  color: #fff;
  border: 0;
  outline: 0;
  width: 100%;
  height: 100%;
}
.h-main .mat-mdc-outlined-button .mat-mdc-button-ripple,
.h-main .mat-mdc-outlined-button .mdc-button__ripple {
  display: none;
}

.m-auto {
  margin: auto;
}
app-custom-select,
app-custom-select input {
  cursor: pointer;
}
.h-main .next-btn .mat-mdc-button:focus {
  outline-color: var(--accent);
  outline-style: solid;
  outline-width: 3px;
}
.h-main
  .mdc-checkbox__native-control:focus:focus:not(:checked)
  ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:focus:focus:not(:indeterminate)
  ~ .mdc-checkbox__background {
  outline-color: var(--accent);
  outline-style: solid;
  outline-width: 3px;
}

.h-main
  .mat-mdc-radio-button
  .mdc-radio__native-control:focus
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  outline-color: var(--accent);
  outline-style: solid;
  outline-width: 3px;
}

.h-main .mat-bottom-sheet-container {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 56px 32px 48px 32px;
  max-width: 600px;
}

.h-main .mat-mdc-radio-button .mdc-radio {
  padding: 0;
}

.h-main .mdc-line-ripple {
  display: none;
}
.h-main .mdc-text-field--filled .mdc-floating-label--float-above {
  color: var(--primary-color) !important;
}
.mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled)
  .mdc-floating-label--float-above {
  color: var(--red) !important;
}

.h-main .mdc-text-field--filled {
  border: 1px solid var(--gray-2);
  border-radius: 4px;
}

.h-main .mat-mdc-form-field {
  width: 100%;
  margin-bottom: var(--size-m);
}
.h-main .text-capitalize .mat-mdc-form-field {
  text-transform: capitalize;
}
.h-main .adress-group .mat-mdc-form-field {
  margin-bottom: var(--size-s);
  position: relative;
}

.h-main .adress-group .mat-mdc-form-field:after {
  content: '';
  width: 2px;
  height: 8px;
  background: var(--primary-color);
  display: block;
  position: absolute;
  right: 24px;
  bottom: -8px;
}

.h-main .adress-group .mat-mdc-form-field:last-child::after {
  display: none;
}

.h-main .adress-group .mat-mdc-form-field:last-child {
  margin-bottom: var(--size-m);
}

.h-main .mat-mdc-form-field-hint-wrapper,
.h-main .mat-mdc-form-field-error-wrapper {
  position: relative;
  padding: 0;
}

.h-main .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
  font-weight: 500;
}

.h-main .mdc-text-field--filled .mdc-floating-label--float-above {
  font-weight: 400;
}

.mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled),
.mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled),
.h-main .mdc-text-field--invalid .mdc-text-field--filled {
  border-color: var(--mdc-filled-text-field-error-label-text-color);
}

.h-main .hear-us.invalid-field,
.invalid-field .input-icon,
.mdc-text-field--invalid .input-icon,
.mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled)
  i {
  color: var(--red);
}
.mdc-text-field--filled:not(.mdc-text-field--disabled)
  .mdc-floating-label
  > span {
  display: none;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover
  .mdc-floating-label
  > span {
  color: var(--primary-color);
}
.mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label {
  font-weight: 500;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--invalid
  .mdc-floating-label
  > span {
  color: var(--red);
}
.required-text {
  color: var(--red) !important;
}
.mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)::after {
  margin-left: 5px;
}
.h-main form mat-form-field.ng-valid.mat-mdc-form-field label > span {
  color: var(--primary-color);
}

.h-main form mat-form-field.ng-invalid.mat-mdc-form-field > label > span {
  color: var(--red);
}

.h-main .mat-mdc-form-field-bottom-align::before {
  display: none;
}

.h-main .mat-mdc-form-field-hint {
  padding: var(--size-s) var(--size-m) 0 var(--size-m);
  line-height: 1;
}

/* checkbox */
.h-main .mat-mdc-checkbox {
  display: block;
  margin: var(--size-m) 0;
}

.h-main .mdc-checkbox {
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
  flex: 1;
}

.h-main .checkbox-group {
  padding-bottom: var(--size-m);
}

.h-main .checkbox-group .mat-mdc-checkbox {
  margin: 0 0 var(--size-m) 0;
}

.h-main .mdc-checkbox .mdc-checkbox__background {
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  border-radius: 4px;
}

.h-main .mdc-checkbox__checkmark {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
}

.h-main
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true'])
  ~ .mdc-checkbox__background {
  background-color: var(--white);
}

.h-main .mat-mdc-checkbox label {
  padding-left: var(--size-xl);
}

.h-main .mdc-checkbox .mdc-checkbox__native-control ~ .mdc-checkbox__ripple,
.h-main
  .mdc-checkbox:active
  .mdc-checkbox__native-control
  ~ .mdc-checkbox__ripple,
.h-main
  .mdc-checkbox:focus
  .mdc-checkbox__native-control
  ~ .mdc-checkbox__ripple,
.h-main .mat-ripple.mat-mdc-checkbox-ripple {
  display: none;
}

/* Upload */
.upload-card {
  margin-top: var(--size-m);
  margin-bottom: var(--size-l);
}

.upload-title {
  color: var(--primary-color);
  font-size: var(--font-size-s);
  padding-bottom: var(--size-l);
  font-weight: 700;
}

.option {
  color: var(--primary-color);
  font-size: var(--font-size-s);
  padding: var(--size-m) 0;
  font-weight: 500;
  cursor: pointer;
}

.option-border {
  border-bottom: 1px solid var(--gray-1);
}

.upload-dialog {
  padding-bottom: var(--size-s);
}

.icon-red {
  color: var(--red);
}

.h-main.mat-typography h2 {
  margin: 0;
}
.take-img {
  padding-right: 24px;
}
.drawerTitle {
  font-size: var(--font-size-m);
  color: var(--primary-color);
  margin-bottom: var(--size-l);
  font-weight: 700;
}
.h-main .take-img .mat-mdc-radio-button .mdc-radio {
  display: none !important;
}
.h-main .take-img video {
  max-width: 600px;
}

.take-pic {
  background-color: var(--primary-color);
  width: var(--size-xl);
  height: var(--size-xl);
  border-radius: 50%;
  margin: auto;
  display: block;
}
.h-main .take-img .mat-mdc-radio-group {
  display: flex;
  justify-content: space-between;
  height: var(--size-l);
  flex-direction: column;
  gap: 16px;
  padding-bottom: 32px;
  cursor: pointer;
}
.camera-container {
  max-width: 600px;
}
.starter-title {
  color: var(--primary-color);
  font-size: var(--font-size-l);
  font-weight: 700;
  padding-bottom: var(--size-m);
  line-height: 1.1;
}

.starter-body {
  color: var(--primary-color);
  font-size: var(--font-size-s);
  line-height: var(--line-height-l);
  font-weight: 500;
  padding-bottom: var(--size-xxl);
}

.resume-red {
  color: var(--red);
}

.sub-title {
  font-size: var(--font-size-s);
  color: var(--primary-color);
  font-weight: 500;
  padding-top: var(--size-m);
  padding-bottom: var(--size-m);
}

.sub-desc {
  font-size: var(--font-size-s);
  color: var(--primary-color);
  font-weight: 400;
  padding-bottom: var(--size-m);
}

.medical-title {
  font-size: var(--font-size-m);
  color: var(--primary-color);
  font-weight: 500;
  padding-top: var(--size-sl);
  padding-bottom: var(--size-m);
  display: flex;
  align-items: center;
}

.medical-title > .mat-icon {
  padding-right: var(--size-s);
}

.med-close {
  width: 100%;
  text-align: right;
  color: var(--red);
}

.privacy-title {
  font-size: var(--font-size-s);
  color: var(--primary-color);
  font-weight: 500;
  padding-bottom: var(--size-m);
}

.h-main .mat-button-toggle-group {
  width: 100%;
  margin-bottom: var(--size-m);
}

.h-main .mat-button-toggle-appearance-standard {
  width: 25%;
}

.body-selector {
  width: 100%;
  height: auto;
  display: block;
  margin-bottom: var(--size-l);
}

.body-selector app-body-front,
.body-selector app-body-front svg,
.body-selector app-body-back,
.body-selector app-body-back svg,
.body-selector app-body-left,
.body-selector app-body-left svg,
.body-selector app-body-right,
.body-selector app-body-right svg {
  width: 100%;
  height: 100%;
}

.color-0 {
  fill: #ffffff;
}
.color-1 {
  fill: #1b998b;
}
.color-2 {
  fill: #63ac7c;
}
.color-3 {
  fill: #acc06d;
}
.color-4 {
  fill: #f4d35e;
}
.color-5 {
  fill: #f4b857;
}
.color-6 {
  fill: #f59e50;
}
.color-7 {
  fill: #f58349;
}
.color-8 {
  fill: #f36d53;
}
.color-9 {
  fill: #f0585c;
}
.color-10 {
  fill: #ee4266;
}

.svgSelected {
  stroke: var(--primary-color);
  stroke-width: 0.01;
  opacity: 1;
}

.svgUnselected {
  fill: var(--white);
  stroke: var(--primary-color);
  stroke-width: 0.01;
  opacity: 1;
}

.svgSelectedback {
  fill: var(--red);
  stroke: var(--primary-color);
  stroke-width: 1;
  opacity: 1;
}

.svgUnselectedback {
  stroke: var(--primary-color);
  stroke-width: 1;
  opacity: 1;
}

.h-main .textarea .mdc-floating-label {
  text-wrap: balance;
}

.h-main .textarea .mdc-floating-label--float-above {
  text-wrap: nowrap;
}

.h-main .done-title {
  color: var(--primary-color);
  font-size: var(--size-l);
  font-weight: 700;
  padding-bottom: var(--size-s);
}

.h-main .done-h-main {
  text-align: center;
  height: 100vh;
  padding: 0 32px 0 32px;
  background: var(--h-main-background-color);
}

.h-main .done-body {
  text-align: center;
  color: var(--primary-color);
  font-size: var(--font-size-m);
  font-weight: 500;
  line-height: 1.4;
  max-width: 500px;
}

.h-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flex-1 {
  flex: 0.8;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.flex-2 {
  flex: 0.2;
}

.h-main .edit-btn {
  cursor: pointer;
  width: 100%;
  height: 56px;
  font-size: var(--font-size-s);
  font-weight: 700;
  background: var(--white) !important;
  color: var(--primary-color) !important;
  border: 0;
  margin-left: 0;
  box-shadow: none !important;
  border-radius: 5px;
}
.h-main .healent-form-input-icon-2 {
  cursor: pointer;
}

.h-main .edit-btn .mat-icon {
  font-size: var(--font-size-l);
  width: var(--font-size-l);
  height: var(--font-size-l);
}

.h-main .finish-btn {
  cursor: pointer;
  width: 100%;
  height: 56px;
  font-weight: 700;
}

.h-main .mat-step-icon {
  width: var(--size-l);
  height: var(--size-l);
  font-size: var(--font-size-s);
}

.h-main .mat-vertical-stepper-header {
  padding: 16px;
  height: auto;
  margin-top: 0;
  margin-bottom: 0;
}

.h-main .mat-vertical-content-container {
  margin-left: var(--size-l);
  margin-right: var(--size-s);
}

.h-main .mat-stepper-vertical-line::before {
  top: calc(16px - calc((var(--mat-stepper-header-height) - 24px) / 2));
  bottom: calc(16px - calc((var(--mat-stepper-header-height) - 24px) / 2));
}

.h-main .mat-vertical-content {
  padding: 0px 16px 48px 16px;
}

@media screen and (max-width: 600px) {
  .webcam-wrapper,
  .webcam-wrapper video {
    width: 100% !important;
  }
}

@media screen and (max-width: 500px) {
  .table-authorizedContacts td {
    padding: 4px 4px !important;
  }
}

.h-body-select-title {
  color: var(--red);
  font-size: var(--font-size-m);
  margin: 0;
  margin-bottom: var(--size-m);
  font-weight: 500;
}
.h-body-select-placeholder {
  color: var(--primary-color);
  font-size: var(--font-size-s);
  line-height: var(--line-height-m);
  margin: 0;
  margin-bottom: var(--size-m);
  font-weight: 500;
}
.h-body-select-Selected {
  color: var(--red);
  font-size: var(--font-size-m);
  margin: 0;
  margin-bottom: var(--size-s);
  font-weight: 500;
  text-align: center;
}
.h-body-select-Selected-list {
  color: var(--primary-color);
  font-size: var(--font-size-s);
  line-height: var(--line-height-l);
  margin: 0;
  margin-bottom: var(--size-l);
  font-weight: 500;
  text-align: center;
}
.h-body-select-worseListTitle {
  color: var(--primary-color);
  font-size: var(--font-size-s);
  line-height: var(--line-height-m);
  margin: 0;
  margin-bottom: var(--size-m);
  font-weight: 500;
}
.h-main .h-worseList-toggle-group {
  display: flex;
  flex-wrap: wrap;
  gap: var(--size-s);
  border: 0 !important;
  padding-bottom: var(--size-l);
  margin-bottom: 0;
}
.h-main .h-worseList-toggle-group .h-worseList-button-toggle {
  background: white;
  padding: 0 var(--size-m);
  border-radius: 26px;
  height: var(--size-xml);
  line-height: var(--size-xml);
  color: var(--primary-color);
  font-size: var(--font-size-s);
  font-weight: 500;
  border: 0;
  border-left: 0 !important;
  width: auto;
}
.h-main .h-worseList-toggle-group .h-worseList-button-toggle.selected {
  background: var(--red);
  color: var(--white);
}
.pain-mark {
  width: 30px;
  height: 30px;
  border: 1px var(--primary-color) solid;
  border-radius: 50%;
  background: var(--h-main-background-color);
  font-size: 0.015em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: normal;
}
[class^='pain-mark pain-mark-B'] {
  border: 1px var(--primary-color) solid;
  border-radius: 50%;
  background: var(--h-main-background-color);
  font-size: 2.2em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
[class^='pain-mark pain-mark-B'] circle {
  r: 2.2rem;
  background: var(--h-main-background-color);
}

.h-main .mat-mdc-button:not(:disabled) {
  color: var(--primary-color);
}
.mat-mdc-form-field-error {
  padding-top: var(--size-xs);
}
.h-main .next-btn .mat-mdc-button.mat-mdc-button-disabled {
  background-color: var(--gray-1);
}
.h-main .next-btn .mat-mdc-button {
  cursor: pointer;
  background-color: var(--primary-color);
  color: var(--white);
  width: 160px;
  height: 56px;
  font-size: var(--font-size-s);
  font-weight: 700;
}
.h-main .mat-mdc-button {
  cursor: pointer;
}
.hint {
  padding: var(--size-xs) var(--size-m) 0 var(--size-m);
  -webkit-font-smoothing: antialiased;
  font-family: var(--mat-form-field-subscript-text-font);
  line-height: var(--mat-form-field-subscript-text-line-height);
  font-size: var(--mat-form-field-subscript-text-size);
  letter-spacing: var(--mat-form-field-subscript-text-tracking);
  font-weight: var(--mat-form-field-subscript-text-weight);
}
.h-main .middleName .mat-mdc-form-field-icon-suffix {
  display: none;
}
.h-main .middleName app-custom-input:first-child {
  width: 63%;
  display: inline-block;
  margin-right: 2%;
}
.h-main .middleName app-custom-input:last-child {
  width: 35%;
  display: inline-block;
}
.h-main .phone-group {
  margin: var(--size-l) 0;
}
.h-main .phone-group .mat-mdc-form-field {
  margin-bottom: 0;
  position: relative;
}

.h-main .phone-group .mat-mdc-form-field::before {
  content: '';
  width: 2px;
  height: 16px;
  background: var(--primary-color);
  display: block;
  position: absolute;
  left: 15px;
  bottom: -16px;
}
.h-main .phone-group .mat-mdc-radio-group {
  flex-direction: column;
  height: auto;
  gap: 16px;
  margin-top: 16px;
}
.h-main .phone-group .mat-mdc-radio-group mat-radio-button {
  position: relative;
}
.h-main .phone-group .mat-mdc-radio-group mat-radio-button:first-child::before {
  content: '';
  width: 2px;
  height: 16px;
  background: var(--primary-color);
  display: block;
  position: absolute;
  left: 15px;
  bottom: -16px;
}
.h-main .hint-input-margin {
  margin-top: -16px;
  display: block;
  margin-bottom: 16px;
}
.h-main .flex-row-reverse mat-radio-group {
  flex-direction: row-reverse;
}
.h-main .underline .mdc-form-field > label {
  text-decoration: underline;
  text-underline-offset: 4px;
}
.h-main .mdc-card__media:first-child {
  height: auto;
  object-fit: contain;
  max-height: 600px;
}
.h-main .phoneInput {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.h-main .phoneInput .mat-mdc-select-value {
  color: var(--mdc-filled-text-field-input-text-color);
  font-weight: 500;
}
.h-main .phoneInput .mat-mdc-select-arrow-wrapper {
  transform: translateY(0px) !important;
}
.h-main .phoneInput .mat-mdc-select-arrow-wrapper svg {
  fill: var(--primary-color);
}
.h-main .phoneInput .mat-mdc-select {
  display: inline-flex;
  width: auto;
  min-width: var(--size-xl);
  margin-right: -10px;
}
.h-main .phoneInput .mat-mdc-select-arrow-wrapper {
  display: none !important;
}
.w-100 {
  width: 100% !important;
}

.m-h-16 {
  margin-left: 16px;
  margin-right: 16px;
}

.slider-title {
  color: var(--primary-color);
  margin-top: 0;
  margin-bottom: 64px;
  padding-left: 32px;
  padding-right: 32px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}
.slider-select-title {
  color: var(--primary-color);
  margin-top: 32px;
  margin-bottom: 32px;
  padding-left: 0px;
  padding-right: 32px;
  font-size: 20px;
  font-weight: 700;
  text-align: left;
}

.labels {
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.labels span {
  font-size: 14px;
  color: var(--primary-color);
  font-weight: 500;
  line-height: 16px;
}
.mat-mdc-slider .mdc-slider__track--active {
  height: 0px !important;
}
.mdc-slider__track--active_fill {
  border-color: var(--primary-color) !important;
}
.mdc-slider__track--inactive {
  background: var(--primary-color) !important;
  opacity: 1 !important;
  height: 2px !important;
}
.mdc-slider__track--inactive_fill {
  border-color: var(--primary-color) !important;
}
.btn-slider.mat-mdc-raised-button.nextBtn:not(:disabled) {
  background-color: var(--mdc-protected-button-container-color);
  width: 160px;
  height: 56px;
  margin: auto;
  margin-top: var(--size-l);
  cursor: pointer;
}
.btn-slider.mat-mdc-raised-button.nextBtn {
  background-color: var(--gray-1);
  width: 160px;
  height: 56px;
  margin: auto;
  margin-top: var(--size-l);
  cursor: pointer;
}

:root {
  --color-0: #ffffff;
  --color-1: #1b998b;
  --color-2: #63ac7c;
  --color-3: #acc06d;
  --color-4: #f4d35e;
  --color-5: #f4b857;
  --color-6: #f59e50;
  --color-7: #f58349;
  --color-8: #f36d53;
  --color-9: #f0585c;
  --color-10: #ee4266;
}
app-pain-slider {
  display: block;
  width: 98%;
}
.h-main .mat-mdc-slider .mdc-slider__thumb-knob {
  background-color: var(--white) !important;
  border: 1px var(--color-1) solid !important;
  width: 30px !important;
  height: 30px !important;
}
.mat-mdc-slider.value-1 .mdc-slider__thumb-knob {
  border: 1px var(--color-1) solid !important;
}
.mat-mdc-slider.value-2 .mdc-slider__thumb-knob {
  border: 1px var(--color-2) solid !important;
}
.mat-mdc-slider.value-3 .mdc-slider__thumb-knob {
  border: 1px var(--color-3) solid !important;
}
.mat-mdc-slider.value-4 .mdc-slider__thumb-knob {
  border: 1px var(--color-4) solid !important;
}
.mat-mdc-slider.value-5 .mdc-slider__thumb-knob {
  border: 1px var(--color-5) solid !important;
}
.mat-mdc-slider.value-6 .mdc-slider__thumb-knob {
  border: 1px var(--color-6) solid !important;
}
.mat-mdc-slider.value-7 .mdc-slider__thumb-knob {
  border: 1px var(--color-7) solid !important;
}
.mat-mdc-slider.value-8 .mdc-slider__thumb-knob {
  border: 1px var(--color-8) solid !important;
}
.mat-mdc-slider.value-9 .mdc-slider__thumb-knob {
  border: 1px var(--color-9) solid !important;
}
.mat-mdc-slider.value-10 .mdc-slider__thumb-knob {
  border: 1px var(--color-10) solid !important;
}
.mat-mdc-slider .mdc-slider__value-indicator {
  background: var(--color-1) !important;
  opacity: 1 !important;
}
.mat-mdc-slider.value-1 .mdc-slider__value-indicator {
  background: var(--color-1) !important;
}
.mat-mdc-slider.value-2 .mdc-slider__value-indicator {
  background: var(--color-2) !important;
}
.mat-mdc-slider.value-3 .mdc-slider__value-indicator {
  background: var(--color-3) !important;
}
.mat-mdc-slider.value-4 .mdc-slider__value-indicator {
  background: var(--color-4) !important;
}
.mat-mdc-slider.value-5 .mdc-slider__value-indicator {
  background: var(--color-5) !important;
}
.mat-mdc-slider.value-6 .mdc-slider__value-indicator {
  background: var(--color-6) !important;
}
.mat-mdc-slider.value-7 .mdc-slider__value-indicator {
  background: var(--color-7) !important;
}
.mat-mdc-slider.value-8 .mdc-slider__value-indicator {
  background: var(--color-8) !important;
}
.mat-mdc-slider.value-9 .mdc-slider__value-indicator {
  background: var(--color-9) !important;
}
.mat-mdc-slider.value-10 .mdc-slider__value-indicator {
  background: var(--color-10) !important;
}
.mat-mdc-slider .mdc-slider__value-indicator::before {
  border-top-color: var(--color-1) !important;
}
.mat-mdc-slider.value-1 .mdc-slider__value-indicator::before {
  border-top-color: var(--color-1) !important;
}
.mat-mdc-slider.value-2 .mdc-slider__value-indicator::before {
  border-top-color: var(--color-2) !important;
}
.mat-mdc-slider.value-3 .mdc-slider__value-indicator::before {
  border-top-color: var(--color-3) !important;
}
.mat-mdc-slider.value-4 .mdc-slider__value-indicator::before {
  border-top-color: var(--color-4) !important;
}
.mat-mdc-slider.value-5 .mdc-slider__value-indicator::before {
  border-top-color: var(--color-5) !important;
}
.mat-mdc-slider.value-6 .mdc-slider__value-indicator::before {
  border-top-color: var(--color-6) !important;
}
.mat-mdc-slider.value-7 .mdc-slider__value-indicator::before {
  border-top-color: var(--color-7) !important;
}
.mat-mdc-slider.value-8 .mdc-slider__value-indicator::before {
  border-top-color: var(--color-8) !important;
}
.mat-mdc-slider.value-9 .mdc-slider__value-indicator::before {
  border-top-color: var(--color-9) !important;
}
.mat-mdc-slider.value-10 .mdc-slider__value-indicator::before {
  border-top-color: var(--color-10) !important;
}
.bottom-title {
  color: var(--primary-color);
  font-size: var(--font-size-m);
  padding: 0;
  font-weight: 700;
  margin-bottom: var(--size-s);
}

.bottom-radio {
  display: flex;
  padding-top: var(--size-sl);
  width: 100%;
}
.bottom-radio .mdc-radio {
  width: var(--size-sl);
  height: var(--size-sl);
}
.bottom-radio .mdc-radio__background {
  width: var(--size-sl);
  height: var(--size-sl);
}
.bottom-radio .mdc-form-field > label {
  padding-left: var(--size-s);
}
.bottom-radio .mat-mdc-radio-button.mat-accent,
.bottom-radio .mdc-form-field {
  width: 100%;
}
.h-main .selector .mdc-label {
  color: var(--primary-color);
  font-size: var(--font-size-s);
  font-weight: 500;
  padding-top: 0;
  width: 100%;
  cursor: pointer;
}
.bottom-radio
  .mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  background-color: var(--white);
  border-color: var(--primary-color);
  border-width: 1px;
}
.bottom-radio .mdc-radio__inner-circle {
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  background: var(--accent);
  border-width: 0;
  transform: translate(-50%, -50%) scale(0);
}
.bottom-radio
  .mdc-radio__native-control:checked
  + .mdc-radio__background
  .mdc-radio__inner-circle {
  transform: translate(-50%, -50%) scale(1);
}
.bottom-radio
  .mat-mdc-radio-button
  .mdc-radio:not(:disabled):active
  .mdc-radio__native-control:enabled:checked
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border-color: var(--primary-color);
}
.bottom-radio
  .mat-mdc-radio-button
  .mdc-radio:hover
  .mdc-radio__native-control:not([disabled]):not(:focus)
  ~ .mdc-radio__background::before,
.bottom-radio .mat-mdc-radio-button .mat-radio-ripple .mat-ripple-element,
.bottom-radio
  .mat-mdc-radio-button.mat-mdc-radio-checked
  .mdc-radio__background::before {
  display: none;
}
.right-selector {
  cursor: pointer;
  position: relative;
  width: fit-content;
  margin: auto;
}
.left-selector {
  cursor: pointer;
  position: relative;
  width: fit-content;
  margin: auto;
}
.front-selector {
  cursor: pointer;
  position: relative;
  width: fit-content;
  margin: auto;
}
.back-selector {
  cursor: pointer;
  position: relative;
  width: fit-content;
  margin: auto;
}
.h-main .mdc-slider .mdc-slider__value-indicator {
  transform: scale(1) !important;
}
.btn-slider.mat-mdc-raised-button:not(:disabled) {
  background-color: var(--mdc-protected-button-container-color);
  width: 160px;
  height: 56px;
  margin: auto;
  margin-top: var(--size-l);
  cursor: pointer;
}

.mark {
  position: absolute;
  transform: translate(-50%, -50%); /* Center the mark */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px; /* Adjust size as needed */
  height: 20px; /* Adjust size as needed */
  background-color: white;
  border: 1px solid black;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
}

.mark span {
  font-size: 12px; /* Adjust font size as needed */
  color: black;
}
.pain-mark-F_leftShoulder {
  transform: translateX(1.02em);
}
.pain-mark-F_abdomen {
  transform: translateY(1.04em);
}
.pain-mark-F_abdomen {
  transform: translateY(1.042em);
}
.pain-mark-F_lowerBody {
  transform: translateY(1.04em);
}
.pain-mark-F_rightShoulder {
  transform: translateX(-1.02em);
}
.pain-mark-F_rightUpperLeg {
  transform: translateY(1.02em);
}
.pain-mark-F_leftUpperLeg {
  transform: translateY(1.02em);
}
.pain-mark-R_upperBody {
  transform: translate(1.04em, -1.02em);
}
.pain-mark-R_abdomen {
  transform: translateX(1.02em);
}
.pain-mark-R_lowerBody {
  transform: translate(1.01em, -1.03em);
}
.pain-mark-R_rightShoulder {
  transform: translateY(-1.02em);
}
.pain-mark-R_rightUpperLeg {
  transform: translateY(1.04em);
}
.pain-mark-R_rightFoot {
  transform: translate(-1.02em, 1.01em);
}
.pain-mark-L_upperBody {
  transform: translate(-1.03em, -1.01em);
}
.pain-mark-L_lowerBody {
  transform: translateY(-1.03em);
}
.pain-mark-L_abdomen {
  transform: translateX(-1.02em);
}
.pain-mark-L_leftUpperLeg {
  transform: translateY(1.03em);
}
.pain-mark-L_leftFoot {
  transform: translate(1.025em, 1.015em);
}
.pain-mark-B_head {
  transform: translateY(-0.5em);
}
.pain-mark-B_neck {
  transform: translateY(-1em);
}
.pain-mark-B_leftShoulder {
  transform: translate(-1em, -0.5em);
}
.pain-mark-B_rightShoulder {
  transform: translate(1em, -0.5em);
}
.pain-mark-B_rightFoot {
  transform: translateY(1em);
}
.pain-mark-B_leftFoot {
  transform: translateY(1em);
}
.bottom-slider {
  text-align: center;
}
:root {
  --color-0: #ffffff;
  --color-1: #1b998b;
  --color-2: #63ac7c;
  --color-3: #acc06d;
  --color-4: #f4d35e;
  --color-5: #f4b857;
  --color-6: #f59e50;
  --color-7: #f58349;
  --color-8: #f36d53;
  --color-9: #f0585c;
  --color-10: #ee4266;
}
app-pain-slider {
  display: block;
  width: 98%;
}
.mat-mdc-slider .mdc-slider__thumb-knob {
  background-color: var(--main-background-color) !important;
  border: 1px var(--color-1) solid !important;
  width: 30px !important;
  height: 30px !important;
}
.mat-mdc-slider.value-1 .mdc-slider__thumb-knob {
  border: 1px var(--color-1) solid !important;
}
.mat-mdc-slider.value-2 .mdc-slider__thumb-knob {
  border: 1px var(--color-2) solid !important;
}
.mat-mdc-slider.value-3 .mdc-slider__thumb-knob {
  border: 1px var(--color-3) solid !important;
}
.mat-mdc-slider.value-4 .mdc-slider__thumb-knob {
  border: 1px var(--color-4) solid !important;
}
.mat-mdc-slider.value-5 .mdc-slider__thumb-knob {
  border: 1px var(--color-5) solid !important;
}
.mat-mdc-slider.value-6 .mdc-slider__thumb-knob {
  border: 1px var(--color-6) solid !important;
}
.mat-mdc-slider.value-7 .mdc-slider__thumb-knob {
  border: 1px var(--color-7) solid !important;
}
.mat-mdc-slider.value-8 .mdc-slider__thumb-knob {
  border: 1px var(--color-8) solid !important;
}
.mat-mdc-slider.value-9 .mdc-slider__thumb-knob {
  border: 1px var(--color-9) solid !important;
}
.mat-mdc-slider.value-10 .mdc-slider__thumb-knob {
  border: 1px var(--color-10) solid !important;
}
.mat-mdc-slider .mdc-slider__value-indicator {
  background: var(--color-1) !important;
  opacity: 1 !important;
}
.mat-mdc-slider.value-1 .mdc-slider__value-indicator {
  background: var(--color-1) !important;
}
.mat-mdc-slider.value-2 .mdc-slider__value-indicator {
  background: var(--color-2) !important;
}
.mat-mdc-slider.value-3 .mdc-slider__value-indicator {
  background: var(--color-3) !important;
}
.mat-mdc-slider.value-4 .mdc-slider__value-indicator {
  background: var(--color-4) !important;
}
.mat-mdc-slider.value-5 .mdc-slider__value-indicator {
  background: var(--color-5) !important;
}
.mat-mdc-slider.value-6 .mdc-slider__value-indicator {
  background: var(--color-6) !important;
}
.mat-mdc-slider.value-7 .mdc-slider__value-indicator {
  background: var(--color-7) !important;
}
.mat-mdc-slider.value-8 .mdc-slider__value-indicator {
  background: var(--color-8) !important;
}
.mat-mdc-slider.value-9 .mdc-slider__value-indicator {
  background: var(--color-9) !important;
}
.mat-mdc-slider.value-10 .mdc-slider__value-indicator {
  background: var(--color-10) !important;
}
.mat-mdc-slider .mdc-slider__value-indicator::before {
  border-top-color: var(--color-1) !important;
}
.mat-mdc-slider.value-1 .mdc-slider__value-indicator::before {
  border-top-color: var(--color-1) !important;
}
.mat-mdc-slider.value-2 .mdc-slider__value-indicator::before {
  border-top-color: var(--color-2) !important;
}
.mat-mdc-slider.value-3 .mdc-slider__value-indicator::before {
  border-top-color: var(--color-3) !important;
}
.mat-mdc-slider.value-4 .mdc-slider__value-indicator::before {
  border-top-color: var(--color-4) !important;
}
.mat-mdc-slider.value-5 .mdc-slider__value-indicator::before {
  border-top-color: var(--color-5) !important;
}
.mat-mdc-slider.value-6 .mdc-slider__value-indicator::before {
  border-top-color: var(--color-6) !important;
}
.mat-mdc-slider.value-7 .mdc-slider__value-indicator::before {
  border-top-color: var(--color-7) !important;
}
.mat-mdc-slider.value-8 .mdc-slider__value-indicator::before {
  border-top-color: var(--color-8) !important;
}
.mat-mdc-slider.value-9 .mdc-slider__value-indicator::before {
  border-top-color: var(--color-9) !important;
}
.mat-mdc-slider.value-10 .mdc-slider__value-indicator::before {
  border-top-color: var(--color-10) !important;
}
.medications-title {
  color: var(--primary-color);
  font-size: var(--font-size-m);
  font-weight: 500;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 8px;
}
.medications-title i {
  font-size: var(--font-size-l);
}
.medication-usage,
.test-and-image-item,
.authorized-item {
  position: relative;
  padding-bottom: var(--size-m);
}

.h-main .test-and-image-item app-custom-input .hint {
  display: none;
}
.close-icon {
  color: var(--red);
  font-size: var(--font-size-m);
  position: absolute;
  top: 0;
  right: 0;
}
.h-main .dose-freq {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 4px;
}
.h-main .dose-freq > div {
  width: calc(50% - 2px);
}

.h-main .dose-freq app-custom-input {
  width: calc(50% - 2px);
}
.medications-btn {
  cursor: pointer;
  background: var(--white);
  height: var(--size-xml);
  width: 100%;
  color: var(--primary-color);
  font-size: var(--font-size-s);
  font-weight: 700;
  border: 0;
  border-radius: 5px;
  box-shadow: none;
  outline-width: 0;
  outline-color: transparent;
  margin-top: var(--size-s);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.medications-btn-m-t-m {
  margin-top: var(--size-m) !important;
}
.medications-btn i {
  font-size: var(--font-size-m);
  line-height: 1;
  padding-top: 4px;
}
.test-and-image-title {
  color: var(--primary-color);
  font-size: var(--font-size-m);
  font-weight: 500;
  margin: 0;
  padding: 0;
}
.dose-freq app-custom-select,
.dose-freq app-custom-date-picker {
  width: calc(50% - 2px);
}
.test-and-imag-btn {
  cursor: pointer;
  background: var(--white);
  height: var(--size-xml);
  width: 100%;
  color: var(--primary-color);
  font-size: var(--font-size-s);
  font-weight: 700;
  border: 0;
  box-shadow: none;
  outline-width: 0;
  outline-color: transparent;
  margin-top: var(--size-s);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
}
.test-and-imag-btn i {
  font-size: var(--font-size-m);
  line-height: 1;
  padding-top: 4px;
}

.test-and-imag-btn-m-t-m {
  margin-top: var(--size-m) !important;
}

.title-with-icon label {
  font-size: var(--font-size-m);
  font-weight: 500;
  color: var(--primary-color);
  display: flex;
  align-items: center;
}
.title-with-icon.noicon label {
  font-size: var(--font-size-s);
  font-weight: 500;
  color: var(--primary-color);
  display: flex;
  align-items: center;
}

.title-with-icon label i {
  padding-right: var(--size-s);
}
.title-with-icon label span {
  padding-left: var(--size-s);
}
.title-with-icon.noicon label i {
  padding-right: 0;
}
.title-with-icon.noicon label span {
  padding-left: var(--size-s);
}
.title-with-icon .radio-title {
  font-size: var(--font-size-s);
  font-weight: 500;
  color: var(--primary-color);
}
.title-with-icon i {
  font-size: var(--font-size-l);
  line-height: 1;
}
.authorized-title {
  color: var(--red);
  font-size: var(--font-size-m);
  font-weight: 700;
  margin: 0;
  padding: 0;
  margin-bottom: var(--size-m);
}
.authorized-sub-title {
  color: var(--primary-color);
  font-size: var(--font-size-s);
  font-weight: 500;
  line-height: var(--line-height-l);
  margin: 0;
  padding: 0;
  margin-bottom: var(--size-m);
}
.authorized-btn {
  cursor: pointer;
  background: var(--white);
  height: var(--size-xml);
  width: 100%;
  color: var(--primary-color);
  font-size: var(--font-size-s);
  font-weight: 700;
  border: 0;
  box-shadow: none;
  outline-width: 0;
  outline-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
}
.authorized-btn i {
  font-size: var(--font-size-m);
  line-height: 1;
  padding-top: 4px;
}
/* .pointer-none,
.pointer-none .mdc-text-field,
.pointer-none .mdc-text-field label {
  pointer-events: none !important;
}
.pointer-all {
  pointer-events: all;
} */
.h-main .mdc-radio__outer-circle {
  width: 32px;
  height: 32px;
}
.h-main .labelActive .mdc-floating-label--float-above {
  transform: translateY(-50%) !important;
  font-family: var(--mdc-filled-text-field-label-text-font);
  font-size: var(--mdc-filled-text-field-label-text-size);
  font-weight: var(--mdc-filled-text-field-label-text-weight);
  letter-spacing: var(--mdc-filled-text-field-label-text-tracking);
}
.readonly,
.readonly
  .mat-mdc-form-field:not(.mat-form-field-disabled)
  .mat-mdc-floating-label.mdc-floating-label,
.readonly mat-label,
.readonly label {
  pointer-events: none !important;
  cursor: pointer;
}
.readonly > .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background: var(--gray-2);
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.bigNumber input[type='number']::-webkit-inner-spin-button,
.bigNumber input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.bigNumber input[type='number'] {
  -moz-appearance: textfield;
}
app-body-viewer mat-pseudo-checkbox {
  display: none !important;
}
.body-selector app-body-front svg path:hover {
  stroke: var(--accent);
  stroke-width: 0.04;
}
.body-selector svg path:hover {
  stroke: var(--accent);
  stroke-width: 0.04;
}
.body-selector app-body-back svg path:hover {
  stroke: var(--accent);
  stroke-width: 4;
}

@media screen and (max-width: 600px) {
  .mdc-text-field--filled:not(.mdc-text-field--disabled)
    .mdc-floating-label
    span {
    font-size: 18px;
  }
  .mdc-text-field--filled .mdc-floating-label--float-above span {
    transform: translateY(-106%) scale(1);
  }
}
.checked-privacy {
  pointer-events: none;
}
.signed-by {
  font-size: var(--font-size-s);
  line-height: var(--line-height-m);
  font-weight: 400;
  color: var(--accent);
  margin-top: -8px;
}
.not-signed-by {
  font-size: var(--font-size-s);
  line-height: var(--line-height-m);
  font-weight: 400;
  color: var(--red);
  margin-top: -8px;
}
.accept-btn {
  width: auto;
  height: 56px;
  font-size: 16px !important;
  background: var(--accent) !important;
  padding: 0 32px;
}
.table-authorizedContacts {
  margin: 16px 0;
  padding: 16px 0;
  border-collapse: collapse;
  text-align: left;
  width: 100%;
}
.table-authorizedContacts td {
  border: 1px solid var(--primary-color);
  text-align: left;
  padding: 4px 8px;
  margin-right: 16px;
}

.table-info {
  padding: 16px 0;
}
.table-info td {
  padding-right: 10px;
}
/* Default font-weight */

body {
  --mat-form-field-container-text-weight: 500;
}
.h-main .next-btn .mat-mdc-button {
  font-weight: 500;
}
.h-main .mdc-form-field > label {
  font-weight: 500;
}
.h-main .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
  font-weight: 500;
}
.h-main .mdc-form-field > label {
  font-weight: 500;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled)
  .mdc-floating-label
  span {
  font-weight: 500;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled)
  .mdc-floating-label
  span {
  font-weight: 500;
}
.mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label {
  font-weight: 500;
}
.h-main .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
  font-weight: 500;
}
.h-main .front-red + span::after {
  color: var(--red) !important;
}
.h-spacer {
  display: block;
  height: var(--mat-stepper-header-height);
  font-size: 0;
}
@media screen and (max-width: 600px) {
  .h-main .next-btn .mat-mdc-button {
    width: 100%;
    max-width: 50%;
  }
  .h-main .next-btn .submitting .mat-mdc-button {
    width: 100%;
    max-width: 100%;
  }

  .code-input {
    aspect-ratio: 1/1.3;
  }
}
.mat-vertical-stepper-header .mat-step-icon {
  margin-top: 1px;
}
.h-main .hint-input-margin:empty {
  margin: 0 !important;
  display: none;
}
.lineBreak {
  line-break: anywhere;
}
.nowrap {
  text-wrap: nowrap;
}
.h-main i[class^='healent']::before {
  color: var(--primary-color) !important;
}
.h-main .mat-step-icon-state-edit mat-icon {
  color: var(--white);
}
.mdc-text-field--filled.mdc-text-field--disabled .mdc-floating-label,
.mdc-text-field--filled.mdc-text-field--disabled .mdc-text-field__input {
  color: var(--primary-color) !important;
}
.h-main .upload-btn {
  font-weight: bold;
}

.h-main .upload-btn mat-icon {
  color: var(--white);
  margin-right: 8px !important;
}
app-privacy-and-agreements-step-form {
  display: block;
  margin-top: -12px;
}
html {
  -webkit-tap-highlight-color: transparent;
}
mat-button-toggle-group mat-icon {
  color: var(--white);
}
.h-main i.close-icon::before {
  color: var(--red) !important;
}
.no-label label {
  top: 50% !important;
  transform: translateY(-50%) !important;
  color: var(--primary-color) !important;
}
.mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled)
  .mdc-text-field__input {
  color: var(--red);
}
app-upload .mat-mdc-card .upload-icon i {
  font-size: 128px !important;
}

.mat-mdc-form-field-icon-suffix {
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgb(255 255 255) 25%,
    rgb(255 255 255) 100%
  );
}
.mat-form-field-disabled .mat-mdc-form-field-icon-suffix {
  background: transparent;
}
.h-main app-bottom-sheet-radio-form .mdc-label {
  text-transform: lowercase !important;
}

.h-main app-bottom-sheet-radio-form .mdc-label::first-letter {
  text-transform: uppercase !important;
}
.readonly-class {
  pointer-events: none;
}
.readonly-class .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: var(--gray-2);
  cursor: pointer;
}
.readonly-class .mat-mdc-form-field-icon-suffix {
  background: transparent;
}
.readonly-class
  .mdc-text-field--filled:not(.mdc-text-field--disabled)
  .mdc-text-field__input {
  cursor: pointer;
}
.h-main .no-radio-preference.phone-group {
  margin: var(--size-m) 0;
}

.h-main .no-radio-preference.phone-group .mat-mdc-form-field:before {
  display: none !important;
}
.get-verefi-btn
  .mat-mdc-progress-spinner
  .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--gray-2);
}
.get-verefi-btn > div {
  justify-content: center;
  gap: 5px;
}
.upload-btn
  .mat-mdc-progress-spinner
  .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--gray-2);
}
.upload-btn > div {
  justify-content: center;
  gap: 5px;
}

.accept-btn {
  width: 200px;
  height: 56px;
  font-size: 16px !important;
  background: var(--accent) !important;
  padding: 0 32px;
  margin: 0 16px;
  max-width: 360px;
}
.accept-btn-advanced {
  width: 200px;
  height: 56px;
  font-size: 16px !important;
  background: var(--accent) !important;
  padding: 0 32px;
  margin: 0 0;
  max-width: 360px;
}

.hintContainer {
  margin-bottom: 16px;
  margin-top: -8px;
  padding: 0 16px;
}
.hintContainerPlus {
  margin-bottom: 16px;
  margin-top: 8px;
  padding: 0 16px;
}
.hintOptional {
  font-style: italic;
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
.hintRequired {
  font-style: italic;
  color: var(--red);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
.hintError {
  font-style: italic;
  color: var(--red);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
.hintError + .hintText {
  color: var(--red) !important;
}
.mat-form-field-invalid .country-prefix {
  color: var(--red);
}
.hintDisabled {
  font-style: italic;
  color: var(--gray-1);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
.hintDisabled + .hintText {
  color: var(--gray-1);
}
.hintText {
  font-size: 14px;
  font-weight: 400;
  color: var(--primary-color);
  line-height: 16px;
}
app-authorized .hintContainer {
  margin-bottom: 8px;
}
app-chip-selection .hintContainer {
  margin-top: 0px;
}

app-privacy-and-agreements-step-form .hintContainer {
  display: none;
}

.close-icon {
  cursor: pointer;
}
/* .checkboxDone {
  pointer-events: none;
  cursor: default;
} */
.hintContainer.valid .hintText,
.hintContainer.valid .hintRequired,
.hintContainer.valid .hintOptional,
.hintContainer.valid .hintContainerPlus,
.hintContainerPlus.valid .hintRequired,
.hintContainerPlus.valid .hintOptional {
  color: var(--gray-1);
}
.h-main .mdc-text-field--focused {
  border: 1px solid var(--accent);
}
.h-main .mdc-text-field--focused .mdc-floating-label--float-above {
  color: var(--accent) !important;
}

.hide-in-app .hide-me-in-app {
  display: none;
}
/* Override Google Places autocomplete dropdown styles */
.pac-container {
  width: calc(100% - 72px) !important;
  font-size: 14px;
  max-width: 600px;
}
@media screen and (max-width: 600px) {
  .pac-container {
    left: 48px !important;
  }
}
.pac-item {
  position: relative;
  padding: var(--size-s) var(--size-s) !important;
  padding-left: 30px !important;
  border-bottom: 1px solid var(--gray-2) !important;
  font-size: var(--font-size-xs) !important;
  line-height: 1.4 !important;
  text-wrap: wrap;
}
.pac-matched {
  padding-right: var(--size-xs) !important;
}

.pac-item:hover {
  background-color: var(--main-background-color) !important;
}
.pac-item-query {
  font-size: var(--font-size-xs) !important;
  padding-right: var(--size-xs);
  color: var(--primary-color) !important;
}
.pac-icon {
  margin-right: var(--size-s) !important;
  margin-top: 0px !important;
}

.pac-item .pac-icon-marker {
  position: absolute;
  left: var(--size-s);
  top: 50%;
  transform: translateY(-50%);
}

.pac-item span {
  display: flex;
  white-space: nowrap;
}
.h-main .radios-vertical .mat-mdc-radio-group {
  flex-direction: column-reverse;
  gap: var(--size-m);
  height: auto;
}
.select-title-style {
  display: flex;
  justify-content: flex-start;
  margin-bottom: var(--size-m);
}
.selection-title {
  font-size: var(--font-size-m);
  line-height: 28px;
  color: var(--primary-color);
  font-weight: 500;
  margin: 0;
  flex: 1;
}
.select-cercle {
  width: 32px;
  height: 32px;
  background-color: var(--accent);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  margin: 0;
  margin-right: 8px;
  display: inline-flex;
  position: relative;
}
.title-number {
  color: var(--white);
  font-size: var(--font-size-m);
  line-height: 1;
  font-weight: 500;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.h2-section-title {
  font-size: var(--font-size-m);
  line-height: var(--font-size-l);
  color: var(--primary-color);
  font-weight: 500;
  margin: 0;
}
.first-red .selection-title::first-letter {
  color: var(--white);
  background-color: var(--accent);
  padding: 4px 10px;
  border-radius: 100%;
}
.single-step .mat-step-icon {
  display: none;
}
.h-main .single-step .mat-vertical-content-container {
  margin-left: 0;
  margin-right: 0;
}
@media screen and (max-width: 768px) {
  .h-main .single-step .mat-vertical-stepper-header {
    margin: var(--size-m);
    margin-top: 0;
    margin-bottom: var(--size-m);
    padding: 0;
  }
}
.h-main .single-step .mat-step-text-label {
  color: var(--red);
  text-wrap: initial;
  line-height: var(--font-size-l);
}

.stepTitle {
  display: block;
  height: 32px;
}
.hide-reqirad .hintContainer,
.hide-reqirad .hintContainerPlus {
  display: none;
}
.h-main .hide-reqirad .mat-mdc-form-field {
  margin-bottom: 0;
}

.ccmTitle {
  color: var(--red);
  font-size: var(--font-size-m);
  font-weight: 600;
  margin: 0;
  padding: 0;
  margin-bottom: var(--size-m);
}
.ccmParagraph {
  color: var(--primary-color);
  font-size: var(--font-size-s);
  line-height: var(--line-height-l);
  font-weight: 500;
  margin-bottom: var(--size-m);
  margin: 0;
  padding: 0;
  margin-bottom: var(--size-l);
}
.ccmParagraph span.red {
  color: var(--red);
}
.ccmListTitle {
  color: var(--red);
  font-size: var(--font-size-s);
  line-height: var(--line-height-l);
  font-weight: 500;
  margin: 0;
  padding: 0;
  margin-bottom: var(--size-m);
}
.ccmList {
  padding-left: 0px;
  list-style: none;
  margin-bottom: var(--size-l);
}
.ccmList li {
  color: var(--primary-color);
  font-size: var(--font-size-s);
  line-height: 1;
  font-weight: 500;
  margin: 0;
  padding: 0;
  margin-bottom: var(--size-m);
  display: flex;
  align-items: center;
}
.ccmList li::before {
  content: '\e5ca';
  color: var(--red);
  font-size: var(--font-size-l);
  font-family: 'Material Icons';
  padding-right: 8px;
}
.radios-vertical.orderlast mat-radio-button:nth-child(2) {
  order: -1;
}
.h-main .mat-mdc-checkbox-checked + .hintContainer .hintOptional,
.h-main .mat-mdc-checkbox-checked + .hintContainer .hintRequired,
.h-main .mat-mdc-checkbox-checked + .hintContainer .hintError {
  color: var(--gray-1) !important;
}
.h-main .radios-vertical.flexColumn .mat-mdc-radio-group {
  flex-direction: column;
}
.allGood-text {
  color: var(--primary-color);
  font-size: var(--font-size-s);
  font-weight: 500;
  line-height: var(--line-height-l);
  margin: 0;
  padding: 0;
  margin-top: var(--size-m);
}
a.a-btn {
  text-decoration: none !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
svg g text {
  alignment-baseline: central !important;
}
app-select-as-array input {
  cursor: pointer;
}
.select-after app-select-as-array mat-label:after {
  content: ' (Select an option)';
}
.pain-mark.pain-mark-F_hips {
  transform: translateY(4%);
}
label .check-all {
  font-style: italic;
  color: var(--red);
  line-height: var(--font-size-m);
}
